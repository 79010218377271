import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  PhoneAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  signOut,
  updateProfile,
  connectAuthEmulator,
} from 'firebase/auth';
import { myFirebase } from '../firebase/firebase';
import mixpanel from 'mixpanel-browser';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const EMAIL_REQUEST = 'EMAIL_REQUEST';
export const EMAIL_REQUEST_LOGIN = 'EMAIL_REQUEST_LOGIN';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';

export const RESET_REQUEST = 'RESET_REQUEST ';
export const RESET_FAILURE = 'RESET_ERROR';
export const RESET_SUCCESS = 'RESET_SUCCESS';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
export const RECEIVE_TOKEN = 'RECEIVED_TOKEN';
export const RECEIVE_FCM_TOKEN = 'RECEIVED_FCM_TOKEN';
export const FETCH_STATE = 'FETCH_STATE';
export const IS_ONBOARDED = 'IS_ONBOARDED';
export const ONBOARDED_REQUEST = 'ONBOARDED_REQUEST';
export const ONBOARDED_VERIFY_SUCCESS = 'ONBOARDED_VERIFY_SUCCESS';
export const PHONE_REQUEST_TOKEN = 'PHONE_REQUEST_TOKEN';

export const RESET_ERROR = 'RESET_ERROR';

export const resetErrorMsg = () => {
  return {
    type: RESET_ERROR,
  };
};

export const fetchState = () => {
  return { type: 'FETCH_STATE' };
};

export const hasOnboarded = () => {
  return { type: 'IS_ONBOARDED' };
};

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user: user,
  };
};

const loginError = authMsg => {
  return {
    type: LOGIN_FAILURE,
    payload: authMsg,
  };
};

const requestReset = () => {
  return {
    type: RESET_REQUEST,
  };
};

const resetError = authMsg => {
  return {
    type: RESET_FAILURE,
    payload: authMsg,
  };
};

const receiveReset = authMsg => {
  return {
    type: RESET_SUCCESS,
    payload: authMsg,
  };
};

const receiveToken = authToken => {
  return {
    type: RECEIVE_TOKEN,
    payload: authToken,
  };
};

const receiveFCMToken = authToken => {
  return {
    type: RECEIVE_FCM_TOKEN,
    payload: authToken,
  };
};

const phoneRequestToken = phoneToken => {
  return {
    type: PHONE_REQUEST_TOKEN,
    phoneToken: phoneToken,
  };
};

const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

const receiveSignup = user => {
  return {
    type: SIGNUP_SUCCESS,
    user: user,
  };
};

const requestEmailVerify = user => {
  return {
    type: EMAIL_REQUEST,
    user: user,
  };
};

const requestEmailVerifyLogin = user => {
  return {
    type: EMAIL_REQUEST_LOGIN,
    user: user,
  };
};

const receiveEmailVerify = () => {
  return {
    type: EMAIL_SUCCESS,
    isEmailVerified: true,
  };
};

const signupError = authMsg => {
  return {
    type: SIGNUP_FAILURE,
    payload: authMsg,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = authMsg => {
  return {
    type: LOGOUT_SUCCESS,
    payload: authMsg,
  };
};

const logoutError = authMsg => {
  return {
    type: LOGOUT_FAILURE,
    payload: authMsg,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

const onboardedRequest = () => {
  return {
    type: ONBOARDED_REQUEST,
  };
};

export const hasOnboardedVerify = () => {
  return {
    type: ONBOARDED_VERIFY_SUCCESS,
  };
};

export const googlesignup = () => async dispatch => {
  dispatch(requestSignup());

  signInWithPopup(getAuth(myFirebase), new GoogleAuthProvider())
    .then(result => {
      /** @type {firebase.auth.OAuthCredential} */

      //var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      if (user) {
        // Sign up successful

        getIdToken(user).then(function (token) {
          fetch(process.env.REACT_APP_API_URL + 'user', {
            headers: {
              accept:
                'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
              'Content-Type': 'application/json',
              Authorization: 'token ' + token,
            },
            referrerPolicy: 'no-referrer-when-downgrade',
            body: JSON.stringify({
              displayName: user.displayName,
              email: user.email,
              photoUrl: null,
            }),
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
          });
        });

        user.getIdToken().then(function (token) {
          dispatch(receiveToken(token));
        });

        dispatch(receiveSignup(user));
        dispatch(tokenChange());

        addSubscriber(user.email, user.name, '');
        //  "Your account was successfully created! Now you need to verify your e-mail address, please go check your inbox."
      }
    })
    .catch(error => {
      // Handle Errors here.
      //var errorCode = error.code;
      //var errorMessage = error.message;
      //console.log(errorMessage);
      // The email of the user's account used.
      //var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      //var credential = error.credential;
      // ...
    });
};
/*
function listener(user) {
  if (user) {
    console.log(user);
    user.getIdToken().then(function(idToken) {
      console.log(idToken);
      // receiveToken(idToken);
    })
  } else {
    console.log("No USER");
  }
}*/

//getAuth().onIdTokenChanged(listener);

export const refreshToken = tokenId => async dispatch => {
  dispatch(receiveToken(tokenId));
};

export const refreshFCMToken = tokenId => async dispatch => {
  dispatch(receiveFCMToken(tokenId));
};

export const verifyEmail = actionCode => async dispatch => {
  try {
    verifyAuth();
    getAuth()
      .applyActionCode(actionCode)
      .then(resp => {
        console.log('code applied');
        dispatch(receiveEmailVerify());
      })
      .catch(error => {
        console.log('error ' + error);
      });
  } catch (err) {
    console.log('err ' + err);
    dispatch(signupError(err));
  }
};

export const resetPasswordHook = (actionCode, newPass) => async dispatch => {
  try {
    confirmPasswordReset(getAuth(myFirebase), actionCode, newPass)
      .then(resp => {
        console.log('Reset successful');
        dispatch(receiveReset("We've reset your password."));
        logoutUser();
      })
      .catch(err => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const sendEmail = user => async dispatch => {
  try {
    sendEmailVerification(user);
    dispatch(requestEmailVerify(user));
    console.log('Email sent!');
  } catch (err) {
    console.log('Error re-sending email!');
  }
};

const addSubscriber = async (email, fname, lname) => {
  var form = new FormData();
  form.append('email', email);
  form.append('fname', fname);
  form.append('lname', lname);
  var response = await fetch('https://us-central1-kandor-hosting.cloudfunctions.net/addSubscriber', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: new URLSearchParams(form),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  });
};

export const signup = (email, password, name) => async dispatch => {
  try {
    dispatch(requestSignup());
    addSubscriber(email, name, '');

    createUserWithEmailAndPassword(getAuth(myFirebase), email, password)
      .then(dataBeforeEmail => {
        onAuthStateChanged(getAuth(myFirebase), function (user) {
          sendEmailVerification(user);
          dispatch(requestEmailVerify(user));
          console.log('Email Sent');
        });
      })
      .then(dataAfterEmail => {
        onAuthStateChanged(getAuth(myFirebase), function (user) {
          if (user) {
            // Sign up successful
            updateProfile(user, { displayName: name });
            getIdToken(user).then(function (token) {
              fetch(process.env.REACT_APP_API_URL + 'user', {
                headers: {
                  accept:
                    'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                  'Content-Type': 'application/json',
                  Authorization: 'token ' + token,
                },
                referrerPolicy: 'no-referrer-when-downgrade',
                body: JSON.stringify({
                  displayName: name,
                  email: email,
                  photoUrl: null,
                }),
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
              });
            });
            getIdToken(user).then(function (token) {
              dispatch(receiveToken(token));
            });

            dispatch(requestEmailVerify(user));
            dispatch(receiveSignup(user));
            dispatch(tokenChange());

            //  "Your account was successfully created! Now you need to verify your e-mail address, please go check your inbox."
          } else {
            // Signup failed
            dispatch(signupError("Something went wrong, we couldn't create your account. Please try again."));
          }
        });
      })
      .catch(err => {
        dispatch(signupError("Something went wrong, we couldn't create your account. Please try again!"));
      });
  } catch (err) {
    dispatch(signupError(err));
  }
};

export const loginUserPhone = (phone, verify) => dispatch => {
  dispatch(requestLogin());
  return signInWithPhoneNumber(getAuth(myFirebase), phone, verify)
    .then(result => {
      console.log('Verification sent:', result);
      dispatch(phoneRequestToken(result.verificationId));
      return result;
    })
    .catch(err => {
      console.error('Phone login error:', err);
      mixpanel.track('Login error on phone number', {
        error: err.message,
        phone: phone,
      });
      // clevertap.event.push("Login error on phone number", {
      // 	error: err.message,
      // 	phone: phone,
      // });
      throw err;
    });
};

export const verifyUserPhone =
  (otp, phoneToken, phone, referral = null, type = 'ielts') =>
  dispatch => {
    const credential = PhoneAuthProvider.credential(phoneToken, otp);
    signInWithCredential(getAuth(myFirebase), credential)
      .then(data => {
        // clevertap.event.push("Login success")
        mixpanel.track('Login success');
        getIdToken(data.user).then(function (token) {
          fetch(process.env.REACT_APP_API_URL + 'user', {
            headers: {
              accept:
                'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
              'Content-Type': 'application/json',
              Authorization: 'token ' + token,
            },
            referrerPolicy: 'no-referrer-when-downgrade',
            body: JSON.stringify({
              displayName: null,
              email: null,
              photoUrl: null,
              phone: phone,
              referral: referral,
              type: type,
            }),
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
          })
            .then(res => {
              dispatch(receiveToken(token));
              dispatch(receiveLogin(data.user));
              dispatch(tokenChange());
              if (res.status === 204) mixpanel.track('User exist');
              else if (res.status === 200) {
                mixpanel.track('User created');
              }
              fetch(process.env.REACT_APP_API_URL + 'user/details', {
                headers: {
                  accept:
                    'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                  'Content-Type': 'application/json',
                  Authorization: 'token ' + token,
                },
                referrerPolicy: 'no-referrer-when-downgrade',
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
              })
                .then(res => res.json())
                .then(qdata => {
                  if (qdata.is_onboarded === 1) {
                    dispatch(hasOnboarded());
                    localStorage.removeItem('referrer');
                  }
                  dispatch(hasOnboardedVerify());
                  localStorage.removeItem('referrer');
                })
                .catch(e => {
                  console.log(e);
                  dispatch(hasOnboardedVerify());
                  localStorage.removeItem('referrer');
                });
            })
            .catch(err =>
              mixpanel.track('Error on user creation api', {
                error: err.message,
                place: 'user creation api',
                phone: phone,
              })
            );
        });
      })
      .catch(err => {
        console.log();
        // clevertap.event.push('Login error on otp', {
        // 	error: err.message,
        // 	phone: phone,
        // })
        mixpanel.track('Login error on otp', {
          error: err.message,
          phone: phone,
        });
        // dispatch(loginError("Invalid otp!"));
        dispatch(loginError(err.message));
      });
  };

export const loginUser = (email, password) => dispatch => {
  dispatch(requestLogin());
  signInWithEmailAndPassword(getAuth(myFirebase), email, password)
    .then(data => {
      getIdToken(data.user).then(function (token) {
        dispatch(receiveToken(token));
        if (data.user.emailVerified) {
          //SUCCESS
          console.log('email verified!');
        } else {
          console.log('email not verified!');
          // dispatch(requestEmailVerifyLogin(data.user));
          // dispatch(loginError("You haven't verified your e-mail address."));
        }
        dispatch(receiveLogin(data.user));
        dispatch(tokenChange());
      });

      // if (!data.user.emailVerified) {
      //   dispatch(loginError("You haven't verified your e-mail address."));
      // }
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(loginError('Invalid login credentials'));
    });
};

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  signOut(getAuth(myFirebase))
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(logoutError('Error, we were not able to log you out. Please try again.'));
    });
};

export const setUserName = name => {
  onAuthStateChanged(getAuth(myFirebase), user => {
    updateProfile(user, { displayName: name });
  });
};

async function setupEmulators(auth) {
  const authUrl = 'http://localhost:9099';
  await fetch(authUrl);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  dispatch(onboardedRequest());
  var auth = getAuth(myFirebase);
  if (process.env.NODE_ENV === 'development') {
    setupEmulators(auth);
    // connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  }
  onAuthStateChanged(auth, user => {
    if (user === null) {
      dispatch(receiveLogout());
      console.log('auth failed');
      return;
    }
    console.log('auth changed');
    getIdToken(user)
      .then(function (token) {
        dispatch(receiveLogin(user));
        dispatch(receiveEmailVerify());
        dispatch(verifySuccess());
        dispatch(tokenChange());
        dispatch(receiveToken(token));
        fetch(process.env.REACT_APP_API_URL + 'user/details', {
          headers: {
            accept:
              'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
            'Content-Type': 'application/json',
            Authorization: 'token ' + token,
          },
          referrerPolicy: 'no-referrer-when-downgrade',
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
        })
          .then(res => res.json())
          .then(qdata => {
            console.log(qdata);
            if (qdata.is_onboarded === 1) {
              dispatch(hasOnboarded());
            }
            dispatch(hasOnboardedVerify());
          })
          .catch(e => {
            dispatch(hasOnboardedVerify());
            dispatch(receiveLogout());
            return;
          });
      })
      .catch(e => {
        console.log(e);
      });
    if (user.emailVerified) {
      console.log('auth: email verified');
    } else {
      console.log('auth: email not verified');
      // dispatch(requestEmailVerify(user));
    }
  });
};

export const resetPassword = email => async dispatch => {
  try {
    dispatch(requestReset());
    sendPasswordResetEmail(getAuth(myFirebase), email)
      .then(() => dispatch(receiveReset("We've sent you a secured reset link by e-mail.")))
      .catch(() => {
        dispatch(
          resetError(
            "Oops, something went wrong we couldn't send you the e-mail. Try again and if the error persists, contact admin."
          )
        );
      });
  } catch (err) {
    dispatch(resetError(err));
  }
};

export const tokenChange = () => {
  return (dispatch, getState) => {
    var auth = getAuth(myFirebase);
    if (process.env.NODE_ENV === 'development') {
      // setupEmulators(auth);
      // connectAuthEmulator(auth, 'http://localhost:9099')
    }
    onIdTokenChanged(auth, function (user) {
      if (user) {
        getIdToken(user).then(token => {
          dispatch(receiveToken(token));
        });
      }
    });
  };
};

export const gloginUser = () => dispatch => {
  dispatch(requestLogin());

  var provider = new GoogleAuthProvider();

  signInWithPopup(getAuth(myFirebase), provider)
    .then(result => {
      /** @type {firebase.auth.OAuthCredential} */
      //var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      if (user) {
        // Sign up successful

        //Update kandor user with new details
        getIdToken(user).then(function (token) {
          dispatch(receiveToken(token));
          dispatch(receiveLogin(user));
          dispatch(tokenChange());

          fetch(process.env.REACT_APP_API_URL + 'user', {
            headers: {
              accept:
                'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
              'Content-Type': 'application/json',
              Authorization: 'token ' + token,
            },
            referrerPolicy: 'no-referrer-when-downgrade',
            body: JSON.stringify({
              displayName: user.displayName,
              email: user.email,
              photoUrl: null,
            }),
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
          });
        });
        addSubscriber(user.email, user.displayName, '');
        // The user is new, show them a fancy intro screen!
      } else {
        getIdToken(user).then(function (token) {
          dispatch(receiveToken(token));
          dispatch(receiveLogin(user));
          dispatch(tokenChange());
        });
      }

      if (!user.emailVerified) {
        dispatch(loginError("You haven't verified your e-mail address."));
      }
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(loginError('Invalid login credentials'));
    });
};
